import {
  GetCommentsCommandInput,
  GetCommentsCommandOutput,
  GetOrders_DirectsCommandOutput,
  GetOrders_MyCommandInput,
  GetOrders_MyCommandOutput,
  ITO,
  ITOClientConfig,
  Order as ITOOrder,
  PostCommentCommandInput,
  PostCommentCommandOutput,
  SubmitOrderItem,
} from "@amzn/ito-client";
import { SentryFetchHttpHandler } from "@amzn/sentry-fetch-http-handler";
import {
  CreateOrderRequestContent,
  CreateOrderResponseContent,
  GetOrderResponseContent,
  OrderLegacy,
  PreCheckoutResponseContent,
} from "common/types";
import { GetOrdersMyDirectsRequest } from "common/types/orders-types";
import { ORDERING_CONFIG } from "config";

import { CSRFService } from "../csrf-service";
import { IOrdersService } from "./i-orders-service";

export class ITOOrdersService implements IOrdersService {
  private client: ITO;
  private requestHandler: SentryFetchHttpHandler;

  constructor() {
    // Handler
    this.requestHandler = new SentryFetchHttpHandler();

    // Client
    this.client = new ITO({
      endpoint: ORDERING_CONFIG.itoEndpoint,
      region: "*",
      credentials: { accessKeyId: "", secretAccessKey: "" },
      requestHandler: this.requestHandler,
    } as ITOClientConfig);
  }

  /* istanbul ignore next */
  private mapOrder(order: ITOOrder): OrderLegacy {
    return {
      orderId: order.orderId!,
      building: order.location!,
      timestamp: order.requested!.on!,
      currency: order.total!.currency!,
      price: order.total!.amount!,
      status: order.status!,
      items: {},
      requester: order.requested!.by!,
      requiredApprover: order.approver ? order.approver.username! : "",
    };
  }

  public async CreateOrder(request: CreateOrderRequestContent, csrfToken: string): Promise<CreateOrderResponseContent> {
    const items: Record<string, SubmitOrderItem> = {};

    for (const key in request.items) {
      if (Object.prototype.hasOwnProperty.call(request.items, key)) {
        const element = request.items[key];

        let details: any = element.details;
        if (request.comment) {
          // Add comment to item details
          if (!details) details = {};
          details.comment = request.comment;
        }

        items[key] = {
          quantity: element.quantity,
          details,
        };
      }
    }

    this.requestHandler.pushHeader("x-csrf-token", csrfToken);

    const order = await this.client.submitOrder({
      items: items,
    });

    const result: CreateOrderResponseContent = {
      orderId: order.orderId,
      orderStatus: order.status,
    };

    if (order.approver) {
      result.requiredApprover = {
        username: order.approver.username!,
        name: order.approver.firstName!,
        surname: order.approver.lastName,
      };
    }
    return result;
  }

  public async GetPreCheckoutInformation(
    itemTaxonomyId: string,
    csrfToken: string
  ): Promise<PreCheckoutResponseContent> {
    const items: Record<string, SubmitOrderItem> = {};
    items[itemTaxonomyId] = {
      quantity: 1,
    };

    this.requestHandler.pushHeader("x-csrf-token", csrfToken);

    const order = await this.client.submitOrder({
      items: items,
      dryRun: true,
    });

    let result: PreCheckoutResponseContent;
    if (order.approver) {
      result = {
        information: {
          canBeAutoApproved: false,
          requiredApprover: {
            name: order.approver.firstName!,
            surname: order.approver.lastName!,
            username: order.approver.username!,
          },
        },
      };
    } else {
      result = {
        information: {
          canBeAutoApproved: true,
        },
      };
    }

    return result;
  }

  public async GetOrder(orderTaxonomyId: string): Promise<ITOOrder> {
    const order = await this.client.getOrder({
      orderId: orderTaxonomyId,
    });
    return order;
  }

  public async GetOrdersDirect(
    request: GetOrdersMyDirectsRequest
  ): Promise<GetOrders_DirectsCommandOutput | undefined> {
    const result = await this.client.getOrders_Directs({ ...request });
    return result;
  }

  public async UpdateStatus(orderTaxonomyId: string, newStatus: string, csrfToken: string): Promise<ITOOrder> {
    this.requestHandler.pushHeader("x-csrf-token", csrfToken);

    const order = await this.client.updateOrderStatus({
      orderId: orderTaxonomyId,
      status: newStatus,
    });
    return order;
  }

  /* istanbul ignore next */
  public async GetOrderLegacy(orderTaxonomyId: string): Promise<GetOrderResponseContent> {
    const order = await this.client.getOrder({
      orderId: orderTaxonomyId,
    });

    const result: GetOrderResponseContent = {
      order: this.mapOrder(order),
    };

    return result;
  }

  /* istanbul ignore next */
  public async GetOrders_My(
    nextToken: string,
    pageSize: number,
    orderBy: string | undefined
  ): Promise<GetOrders_MyCommandOutput> {
    const request: GetOrders_MyCommandInput = { nextToken, pageSize, orderBy };
    const response: GetOrders_MyCommandOutput = await this.client.getOrders_My(request);
    return response;
  }

  /* istanbul ignore next */
  public async GetComments(taxonomyId: string, pageSize?: number): Promise<GetCommentsCommandOutput> {
    const request: GetCommentsCommandInput = { taxonomyId: taxonomyId, pageSize };
    const response: GetCommentsCommandOutput = await this.client.getComments(request);
    return response;
  }

  /* istanbul ignore next */
  public async PostComment(orderId: string, comment: string, csrfToken: string): Promise<PostCommentCommandOutput> {
    this.requestHandler.pushHeader("x-csrf-token", csrfToken);
    const request: PostCommentCommandInput = {
      taxonomyId: orderId,
      comment: comment,
    };
    const response = await this.client.postComment(request);
    return response;
  }

  /* istanbul ignore next */
  public async HeadOrdersDirect(
    filters: Record<string, string[]>
  ): Promise<{ isManager: boolean; totalResults: string | undefined }> {
    let totalResults: string | undefined;
    let isManager = false;
    try {
      const results = (await this.client.headOrders_Directs({ filters })).totalResults;
      if (results !== undefined) {
        isManager = true;
        totalResults = results > 99 ? "99+" : results.toString();
      }
    } catch {
      totalResults = undefined;
    }
    return { isManager, totalResults };
  }

  public async GetSecurityToken(): Promise<string> {
    const service = new CSRFService();
    return service.getToken();
  }
}
